<template>
    <a-modal
            :visible="visible"
            title='Thêm mới Nhóm sản phẩm'
            okText='Thêm mới'
            cancelText="Hủy"
            @cancel="onModalCancel"
            @ok="() => { $emit('create') }"
    >
        <a-form layout='vertical' :form="form">
            <a-form-item label='Tên nhóm'>
                <a-input
                        v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],
              }
            ]"
                />
            </a-form-item>
            <a-form-item label='Loại nhóm'>
                <a-input type="hidden" v-decorator="['type',
                    {
                        rules: [{ required: true, message: 'Loại nhóm không được bỏ trống!' }]
                    }
                ]"/>
                <a-select v-model="type" placeholder="Chọn loại nhóm" style="width: 150px"  @change="handleTypeChange">
                    <a-select-option value="SLIDE">SLIDE</a-select-option>
                    <a-select-option value="LIST">LIST</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label='Icon nhóm'>
                <a-input type="hidden" v-decorator="[
               'groupIcon',
              {
                rules: [{ required: true, message: 'Icon nhóm không được bỏ trống!' }],
              }
            ]"
                />
                <div v-if="!imageSelected" @click="handleShowModal" class="imageSelect">
                    <a-icon style="font-size: 20px; margin-bottom: 10px;" type="picture" />
                    <span style="text-align: center; display: block; font-weight: 500;">Chọn hình đại diện</span>
                </div>
                <div  @click="handleShowModal" v-else style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;">
                    <span  :style="`display: inline-block; width: 70px; height: 70px; background-size: cover; background-image: url('${imageSelected.url}')`" />
                </div>
            </a-form-item>
        </a-form>
        <select-single-image-modal :onSelectImage="onSelectImage" :onCancel="onCancel" :visible="selectImageVisible" />
    </a-modal>
</template>

<script>
    import SelectSingleImageModal from "../modal/SelectSingleImageModal";

    export default {
        name: "ProductGroupForm",
        components: {SelectSingleImageModal},
        props: ['visible'],
        data() {
            return {
                selectImageVisible: false,
                imageSelected: null,
                type: undefined
            }
        },
        methods: {
            handleShowModal() {
                this.selectImageVisible = true;
            },
            onCancel() {
                this.selectImageVisible = false;
            },
            onSelectImage(image) {
                this.form.setFieldsValue({
                    groupIcon: JSON.stringify(image)
                });
                this.imageSelected = image;
                this.selectImageVisible = false;
            },
            removeImageSelected() {
                this.form.resetFields();
                this.form.setFieldsValue({
                    groupIcon: null
                });
                this.imageSelected = null;
            },
            onModalCancel() {
                this.$emit('cancel');
                this.form.resetFields();
                this.form.setFieldsValue({
                    groupIcon: null,
                    type: null
                });
                this.type = undefined;
                this.imageSelected = null;
            },
            handleTypeChange(type) {
                this.form.setFieldsValue({
                    type
                })
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this)
        }
    }
</script>

<style>
    .imageSelect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        padding: 20px;
        border-radius: 10px;
        border: 1px dotted rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
</style>