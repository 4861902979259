<template>
    <a-layout-content class="GroupList">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout style="padding: 24px 0; background: #fff">
                <a-layout-sider width="400" style="background: #fff; border-right: 1px solid #eeeeee; padding-right: 10px">
                    <div class="content-header-left">
                        <h2>Sắp xếp thứ tự</h2>
                    </div>
                    <a-divider/>
                    <group-product :groups="this.data" />
                    <div class="cate_action">
                        <a-button @click="handleResetGroups">Reset</a-button>
                        <a-popconfirm title='Bạn có chắc chắn?' @confirm="saveAllGroups">
                            <a-button type="primary">Cập nhật</a-button>
                        </a-popconfirm>
                    </div>
                </a-layout-sider>
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <div class="content-header">
                        <h2>{{ this.$route.name }}</h2>
                        <div class="content-header__left">
                            <a-button type="primary" @click="openModal" icon="plus-circle">Thêm nhóm mới</a-button>

                            <a-input-search
                                    placeholder="Tìm kiếm..."
                                    style="width: 200px; margin-left: 30px"
                                    @change="(e) => this.search = e.target.value"
                            />
                        </div>
                    </div>
                    <a-divider />
                    <!--     Table question group       -->
                    <a-table :columns="columns" :dataSource="groupFilter" bordered :rowKey="(record, index) => record.id" :loading="loading"
                             :pagination="true">
                        <template slot-scope="text">
                            <div :key="col">
                                <template>{{text}}</template>
                            </div>
                        </template>

                        <template v-for="col in ['name', 'description']" :slot="col" slot-scope="text, record">
                            <div :key="col">
                                <a-input
                                        v-if="record.editable"
                                        style="margin: -5px 0"
                                        :value="text"
                                        @change="e => handleChange(e.target.value, record.id, col)"
                                />
                                <template v-else>{{text}}</template>
                            </div>
                        </template>

                        <template slot="type" slot-scope="text, record">
                            <div :key="record.id">
                                <a-select v-if="record.editable" :defaultValue="text" style="width: 120px" @change="(value) => handleChange(value, record.id, 'type')">
                                    <a-select-option value="SLIDE">SLIDE</a-select-option>
                                    <a-select-option value="LIST">LIST</a-select-option>
                                </a-select>
                                <template v-else>{{text}}</template>
                            </div>
                        </template>

                        <template slot="groupIcon" slot-scope="text">
                            <a-avatar shape="square" size="large" :src="text.url" />
                        </template>

                        <template slot="productCount" slot-scope="text, record">
                            <div>
                                <span>{{text}} sp</span>
                                <a-divider type="vertical" />
                                <router-link :to="{ path: '/groups/' + record.id + '/products' }" class="nav-link">
                                    <span>Danh sách sản phẩm</span>
                                </router-link>
                            </div>
                        </template>

                        <template slot="active" slot-scope="text, record">
                            <a-checkbox v-if="record.editable" :checked="record.active" @change="(e) => handleChange(e.target.checked, record.id, 'active')"/>
                            <a-popconfirm v-else title='Bạn có chắc chắn?' @confirm="() =>  publishChange(record)">
                                <a-checkbox :checked="record.active"/>
                            </a-popconfirm>
                        </template>
                        <template slot="operation" slot-scope="text, record">
                            <div class='editable-row-operations'>
                        <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                          <a-button @click="() => edit(record.id)">Cập nhật</a-button>
                        </span>

                                <span v-if="record.editable" style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                            <a-popconfirm title='Bạn có chắc chắn?' @confirm="() => saveGroup(record)">
                                <a-button type="primary" style="display: inline-block; margin-bottom: 10px">Lưu lại</a-button>
                            </a-popconfirm>
                            <a-popconfirm title='Bạn có chắc chắn hủy?' @confirm="() => cancel(record.id)">
                                <a-button  style="margin-left: 10px">Hủy</a-button>
                            </a-popconfirm>
                        </span>
                                <span v-else style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                        </span>
                            </div>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>

        </div>

        <!--    Modal add new    -->
        <product-group-form
                ref="productGroupForm"
                :visible="visible"
                @cancel="handleCancel"
                @create="handleCreate"
        />
    </a-layout-content>
</template>

<script>
    import './_GroupList.scss'
    import ProductGroupForm from "@/components/forms/ProductGroupForm";
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    import GroupProduct from "../../components/dd/GroupProductDragable";
    const groupRepository = RepositoryFactory.get('group');

    const columns = [{
        title: 'Id',
        dataIndex: 'id',
        width: '5%',
        scopedSlots: { customRender: 'id' },
    }, {
        title: 'Icon nhóm',
        dataIndex: 'groupIcon',
        width: '5%',
        scopedSlots: { customRender: 'groupIcon' },
    }, {
        title: 'Tên nhóm',
        dataIndex: 'name',
        width: '35%',
        scopedSlots: { customRender: 'name' },
    },{
        title: 'Loại nhóm',
        dataIndex: 'type',
        width: '10%',
        scopedSlots: { customRender: 'type' },
    }, {
        title: 'Số lượng sản phẩm',
        dataIndex: 'productCount',
        width: '25%',
        scopedSlots: { customRender: 'productCount' },
    }, {
        title: 'Active',
        dataIndex: 'active',
        width: '5%',
        scopedSlots: { customRender: 'active' },
    }, {
        title: 'Hành động',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    }];

    export default {
        components: { ProductGroupForm, GroupProduct },
        data() {
            return {
                visible: false,
                columns,
                data: [],
                loading: true,
                cacheData: [],
                search: ''
            }
        },
        methods: {
            handleCancel() {
                this.visible = false;
            },
            handleCreate() {
                this.loading = true;
                const form = this.$refs.productGroupForm.form;
                form.validateFields(async (err, values) => {
                    if (err) {
                        this.loading = false;
                        return;
                    }

                    try {
                        values.priority = this.data.length + 1
                        values.groupIcon = JSON.parse(values.groupIcon);
                        let { data } = await groupRepository.addGroup(values);
                        if (!data.meta.success) throw new Error(data.meta.externalMessage);
                        this.data.push(data.data);
                        this.cacheData.push(data.data);
                        form.resetFields();
                        this.$refs.productGroupForm.removeImageSelected();
                        this.visible = false;
                        this.openNotification("Thêm mới nhóm sản phẩm thành công!", "success");
                    } catch (e) {
                        this.openNotification("Tạo mới nhóm sản phẩm bị lỗi! Vui lòng liên hệ nhóm phát triển!", "error")
                    } finally {
                        this.loading = false;
                    }
                });
            },
            async saveAllGroups () {
                try {
                    this.loading = true;
                    let groups = this.data.map( (group, index) => {
                        group.priority = index + 1;
                        return group;
                    });
                    let response = await groupRepository.updateAllGroups(groups);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage);
                    this.cacheData = JSON.parse(JSON.stringify(this.data));
                    this.openNotification("Cập nhật thành công!", "success");
                } catch (e) {
                    this.openNotification("Cập nhật không thành công! Vui lòng liên hệ System admin", "error");
                } finally {
                    this.loading = false;
                }
            },
            handleResetGroups() {
                this.data = JSON.parse(JSON.stringify(this.cacheData));
            },
            openModal() {
                this.visible = true;
            },
            openNotification (message, type) {
                this.$notification[type]({
                    message: "Hệ thống",
                    description: message
                });
            },
            edit(id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target.editable = true;
                    this.data = newData
                }
            },
            handleChange (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                }
            },
            handleChangeOnActive (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                    this.cacheData = newData.map(item => ({...item}))
                }
            },
            async saveGroup (record) {
                try {
                    this.loading = true;
                    let response = await groupRepository.updateGroup(record.id, record);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage);
                    const newData = [...this.data];
                    const target = newData.filter(item => record.id === item.id)[0];

                    if (target) {
                        delete target.editable;
                        this.data = newData;
                        this.cacheData = newData.map(item => ({ ...item }))
                    }
                    this.openNotification("Cập nhật thành công!", "success");
                } catch (e) {
                    this.openNotification("Cập nhật nhóm sản phẩm bị lỗi! Vui lòng liên hệ quản trị viên!", "error");
                } finally {
                    this.loading = false;
                }
            },

            cancel (id) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => id === item.id)[0])
                    delete target.editable;
                    this.data = newData
                }
            },
            async publishChange(record) {
                record.active = !record.active;
                await this.saveGroup(record);
                this.handleChangeOnActive(record.active, record.id, 'active')
            },
            async fetchAllGroup() {
                let response = await groupRepository.getAll();
                // eslint-disable-next-line no-console
                if (!response.data.meta.success) {
                    this.data = [];
                    throw new Error(response.data.meta.externalMessage)
                }

                this.data = response.data.data.sort((a, b) => a.priority - b.priority);
                this.cacheData = this.data.map(item => ({...item}));
            },
        },
        computed: {
            groupFilter: function () {
                return this.data.map(item => Object.assign({}, item))
                    .filter(item => {
                        return item.name.toUpperCase().includes(this.search.toUpperCase())
                    })
            }
        },
        async created() {
            try {
                this.loading = true;
                await this.fetchAllGroup();
            } catch (e) {
                this.openNotification(e.message)
            } finally {
                this.loading = false;
            }
        }
    };
</script>