var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Thêm mới Nhóm sản phẩm","okText":"Thêm mới","cancelText":"Hủy"},on:{"cancel":_vm.onModalCancel,"ok":function () { _vm.$emit('create') }}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Tên nhóm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],
          }
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Loại nhóm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type',
                {
                    rules: [{ required: true, message: 'Loại nhóm không được bỏ trống!' }]
                }
            ]),expression:"['type',\n                {\n                    rules: [{ required: true, message: 'Loại nhóm không được bỏ trống!' }]\n                }\n            ]"}],attrs:{"type":"hidden"}}),_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"Chọn loại nhóm"},on:{"change":_vm.handleTypeChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('a-select-option',{attrs:{"value":"SLIDE"}},[_vm._v("SLIDE")]),_c('a-select-option',{attrs:{"value":"LIST"}},[_vm._v("LIST")])],1)],1),_c('a-form-item',{attrs:{"label":"Icon nhóm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
           'groupIcon',
          {
            rules: [{ required: true, message: 'Icon nhóm không được bỏ trống!' }],
          }
        ]),expression:"[\n           'groupIcon',\n          {\n            rules: [{ required: true, message: 'Icon nhóm không được bỏ trống!' }],\n          }\n        ]"}],attrs:{"type":"hidden"}}),(!_vm.imageSelected)?_c('div',{staticClass:"imageSelect",on:{"click":_vm.handleShowModal}},[_c('a-icon',{staticStyle:{"font-size":"20px","margin-bottom":"10px"},attrs:{"type":"picture"}}),_c('span',{staticStyle:{"text-align":"center","display":"block","font-weight":"500"}},[_vm._v("Chọn hình đại diện")])],1):_c('div',{staticStyle:{"cursor":"pointer","display":"inline-block","margin":"5px","padding":"5px","border":"1px solid #eee","border-radius":"5px"},on:{"click":_vm.handleShowModal}},[_c('span',{style:(("display: inline-block; width: 70px; height: 70px; background-size: cover; background-image: url('" + (_vm.imageSelected.url) + "')"))})])],1)],1),_c('select-single-image-modal',{attrs:{"onSelectImage":_vm.onSelectImage,"onCancel":_vm.onCancel,"visible":_vm.selectImageVisible}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }