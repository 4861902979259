<template>
    <draggable v-bind="dragOptions"
               @start="isDragging = true"
               @end="isDragging = false" class="item-container" tag="div" :list="groups" :group="{ name: 'g1' }">
        <div class="item-group" :key="el.id" v-for="(el, index) in groups">
            <div class="item">
                <span>{{el.name}}</span>
                <span>{{index + 1}}</span>
            </div>
        </div>
    </draggable>
</template>
<script>
    import draggable from "vuedraggable";
    export default {
        props: ["groups"],
        components: {
            draggable
        },
        computed: {
            dragOptions() {
                return {
                    animation: 1,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        name: "GroupProduct"
    };
</script>
<style scoped>
    .item-container {
        margin: 0;
    }
    .item {
        padding: 1rem;
        border: solid #eee 1px;
        background-color: #fefefe;
        font-weight: bold;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        text-align: left;
    }
</style>